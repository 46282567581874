module.exports = {
    title: {
        login: '登录 - iFi',
        logout: '您已成功退出 - iFi',
        hrnavigation: '导航 - iFi',
        chips: '芯片查询',
        iqtest: '斯坦福-比奈 国际标准智商测试',
        bigfive: '大五类人格测试',
        bigfive_results: '大五问卷 - 查看结果',
        lanesheet: 'Approval Report',
        approvalform: '待处理单据',
        iq_results: 'IQ问卷 - 查看结果',
        keys: '查看问卷口令',
        notfound: '您访问的页面找不到了',
        console: '控制中心 - 360评估系统',
        result: '评估结果 - 评估系统',
        activitylist: '我的活动 - 评估系统'
    },
    notify: {
        title_server: '服务器',
        title_retry: '重试',
        title_notification: '通知',
        title_err_type1: '错误代码： -1',
        title_err_type2: '错误代码: 500',
        title_err_type_ill: '非法操作',
        content_refused: '请求被拒绝',
        content_server: '服务器超时，错误代码：',
        content_loginerr: '账号不存在或密码错误',
        content_ill: '请勿非法操作',
        result_null: '未查询到任何数据',
        dt_token_outofdate: '钉钉鉴权失效，点击重试',
        content_key_err: '请联系管理员获取有效的口令。',
        content_success: '成功',
        content_reported: '已提交',
        cotent_all_items_required: '所有栏目都为必填项'
    },
    text: {
        placeholder: '请输入...',
        placeholder_select: '请选择...',
        placeholder_pick_day: '选择某天',
        message_saved: '已保存',
        message_deleted: '已删除',
        message_nochange: '无改动',
        message_delete_confirm: '确认删除吗？此操作不可逆！',
        sex_man: '男',
        sex_woman: '女',
        text_delete_message_title: '请确认是否删除',
        text_canceled: '已取消',
        text_yes: '是',
        text_no: '否',
        text_notset: '未设置',
        text_confirm_title: '请确认',
        btn_refresh: '刷新',
        text_complete: '已完成',
        text_incomplete: '未完成',
        text_to_be_started: '待开始',
        btn_print: '打印',
        one: '1',
        two: '2',
        three: '3',
        four: '4',
        five: '5',
        six: '6',
        seven: '7',
        eight: '8',
        nine: '9',
        ten: '10',
        eleven: '11',
        identical: '一致',
        not_available: '暂无',
        inused: '已被引用',
        notused: '未被引用',
        text_powered_by: '⚡ Powered by iFi Team.',
        second: '秒',
        minute: '分',
        record: '条记录',
        unused: '未被使用',
        inusing: '正在使用',
        used: '已被使用',
        keyword: '口令',
        status: '状态',
        key_title: '请输入口令',
        option: '选项',
        reset_confirm_body: '确认重置该口令吗？',
        expire_confirm_body: '确认作废该口令吗？',
    },
    common: {
        notify_import: '暂时无法进行导入操作',
        notity_title_message: '信息',
        notify_title_error: '错误',
        notify_empty_assets: '资源服务器错误，请联系管理员',
        btn_revise: '编辑',
        btn_delete: '删除',
        btn_import: '导入',
        btn_save: '保存',
        btn_message_confirm: '确认',
        btn_message_cancel: '取消'
    },
    hrnavigation: {
        top_title: '导航仅供内部使用',
        btn_toiqresults: '查看IQ结果',
        btn_tobigfiveresults: '查看大五结果',
        btn_to_360: '360评估登录'
    },
    login: {
        placehoder_username: '用户名...',
        placehoder_password: '密码...',
        btn_login: '登 录',
        notify_username_null: '账号不能为空',
        notify_password_null: '密码不能为空',
        notify_usernamepassword_lengtherror: '账号密码长度不合规范，请勿非法修改',
        loadingviadingtalk: '等待钉钉回应...',
        notify_dingtalk_not_bound: '当前用户未绑定钉钉，请前往管理页面自行绑定或联系管理员',
        auth_err: '鉴权失败，请稍后再试。'
    },
    logout: {
        title_logout: '您已成功退出',
        btn_tologin: '去登录'
    },
    notfound: {
        title_notfound: '您访问的页面找不到了 - 404',
        subtitle_notfound: '页面可能出错或已被删除，请稍后再试或返回'
    },
    chips: {
        mpn: '型号规格',
        k3id: '物料编码',
        country: '国家',
        distributor: '经销商',
        moq: '最小发货量',
        sku: 'SKU',
        stock: '库存量',
        updated: '更新时间',
        count_stock: '总库存量',
        span_updated: '最后更新时间：',
        notify_chiptemplate_null: '查询模板为空',
        notify_chipdata_partly: '部门数据无法更新',
        tip_apichip_update: '点击更新',
        text_updatefromapi_body: '将会从第三方重新获取数据，会消耗请求数据，请确认！'
    },
    iqtest: {
        tip_top1: ' 本评测适合11岁及以上年龄人士。',
        tip_top2: ' 以下60题目，请于45分钟内完成，可用纸笔计算。',
        title_iqs: '斯坦福-比奈 国际标准智商测试',
        content_title_ask_age: '请选择年龄段：',
        age_1: '10岁及以下',
        age_2: '11岁',
        age_3: '12岁',
        age_4: '13岁',
        age_5: '14岁',
        age_6: '15岁',
        age_7: '16岁及以上',
        notify_age_unselected: '年龄未选择。',
        btn_submit: '提 交',
        notify_unfinished_number1: '第 ',
        notify_unfinished_number2: ' 题未完成！',
        text_rs_score: '您的得分'
    },
    iqresults: {
        top_title: 'IQ测试结果',
        score: '得分',
        duration: '用时',
        label_match_post: '匹配岗位',
        label_standard_score_range: '标准得分',
        label_anal_result: '评估结果',
        text_higher: '高于正常',
        text_normal: '正常',
        text_slightly_lower: '稍低于正常',
        text_lower: '低于正常'
    },
    bigfive: {
        title_ask_info: 'THE BIG FIVE 大五类人格测试',
        content_ask_info: '请您认真填写以下信息：',
        label_fullname: '您的姓名',
        label_post: '您的职位',
        notify_ask_unfinished: '您的信息不完整。',
        toptext_1: '共',
        toptext_2: '个题目。',
        option_1: '非常不符合',
        option_2: '不太符合',
        option_3: '不太确定',
        option_4: '比较符合',
        option_5: '非常符合',
        btn_redo: '重新测试',
        askform_title: 'THE BIG FIVE 大五类人格测试',
        top_tip_text: '在10分钟内不假思索完成。',
        top_tip_text2: '真实呈现自己，而非期待的样子。',
        top_tip_text3: '尽量避免“中立”的答案。',
        targetN: '神经质',
        targetE: '外倾性',
        targetO: '开放性',
        targetA: '友善性',
        targetC: '严谨性',
        explain_title_1: '神经质(Neuroticism)',
        explain_title_2: '外倾性(Extraversion)',
        explain_title_3: '开放性(Openness)',
        explain_title_4: '友善性(Agreeableness)',
        explain_title_5: '严谨性(Conscientiousness)',
        explain_content_1_1: '神经质指个体体验消极情绪的倾向。',
        explain_content_1_2: '神经质维度得分高的人更容易体验到诸如愤怒、焦虑、抑郁等消极的情绪。他们对外界刺激反应比一般人强烈，对情绪的调节能力比较差，经常处于一种不良的情绪状态下。并且这些人思维、决策、以及有效应对外部压力的能力比较差。',
        explain_content_1_3: '神经质维度得分低的人较少烦恼，较少情绪化，比较平静，但这并不表明他们经常会有积极的情绪体验，积极情绪体验的频繁程度是外向性的主要内容。',
        explain_content_2_1: '外向代表了在外界投入的能量。',
        explain_content_2_2: '高外向的人喜欢与人接触，充满活力，经常感受到积极的情绪。他们热情，喜欢运动，喜欢刺激冒险。在一个群体当中，他们非常健谈，自信，喜欢引起别人的注意。',
        explain_content_2_3: '低外向的人比较安静，谨慎，不喜欢与外界过多接触。他们不喜欢与人接触不能被解释为害羞或者抑郁，这仅仅是因为比起高外向的人，他们不需要那么多的刺激，因此喜欢一个人独处。低外向人的这种特点有时会被人误认为是傲慢或者不友好，其实一旦和他接触你经常会发现他是一个非常和善的人。',
        explain_content_3_1: '开放性描述一个人的认知风格。',
        explain_content_3_2: '开放性得分高的人富有想象力和创造力，好奇，欣赏艺术，对美的事物比较敏感。开放性的人偏爱抽象思维，兴趣广泛。开放性的人适合教授等职业。',
        explain_content_3_3: '开放性得分低(即封闭性)的人讲求实际，偏爱常规，比较传统和保守。封闭性的人适合警察、销售、服务性职业等。',
        explain_content_4_1: '代表了“爱”，对合作和人际和谐是否看重。',
        explain_content_4_2: '友善性高的人是善解人意的、友好的、慷慨大方的、乐于助人的，愿意为了别人放弃自己的利益。友善性高的人对人性持乐观的态度，相信人性本善。',
        explain_content_4_3: '友善性低的人则把自己的利益放在别人的利益之上。本质上，他们不关心别人的利益，因此也不乐意去帮助别人。有时候，他们对别人是非常多疑的，怀疑别人的动机。对于某些职位来说，太高的友善性是没有必要的，尤其是需要强硬和客观判断的场合，例如科学家、评论家和士兵。',
        explain_content_5_1: '指我们控制、管理和调节自身冲动的方式。',
        explain_content_5_2: '高严谨性的人容易避免麻烦，能够获得更大的成功。人们一般认为高严谨的人更加聪明和可靠，但是高严谨的人可能是一个完美主义者或者是一个工作狂。极端严谨的个体让人觉得单调、乏味、缺少生气。',
        explain_content_5_3: '低严谨性的人常被认为是快乐的、有趣的、很好的玩伴。但是冲动的行为常常会给自己带来麻烦，虽然会给个体带来暂时的满足，但却容易产生长期的不良后果，比如攻击他人，吸食毒品等等。低严谨性的个体一般不会获得很大的成就。冲动并不一定就是坏事，有时候环境要求我们能够快速决策。'
    },
    bigfiveresults: {
        top_title: '大五人格测试结果',
        survey_name: '测试者',
        survey_post: '应聘职位',
        survey_time: '测试时间',
        empty_results_discription: '暂时没有任何数据。',
        alert_notice_results: '请点击姓名以展开测试结果。'
    },
    lanesheet: {
        transfer_application: '转正',
        job_transfer_application: '调岗',
        resignation_handover_report: '离职移交报告',
        employee: '员工姓名',
        title: '职位',
        department: '部门',
        dateOfEntry: '入职日期',
        transferDate: '转正日期',
        btn_report: 'Report',
        newdept: '转入部门',
        newtitle: '转入职位',
        dateOfTransferred: '调岗时间',
        approver1: '转出部门负责人',
        approver2: '转入部门负责人',
        reason: '调岗原因',
        superior: '上级姓名',
        created_time: '创建时间',
        checked: '标记'
    },
    console: {
        menu_title: '仪表盘',
        menu_question: ' 题目',
        menu_questionnaire: ' 问卷',
        menu_activity: ' 活动',
        menu_properties: ' 资料',
        menu_staff: ' 员工',
        submenu_target: '指标',
        submenu_dept: '部门',
        submenu_weight: '权重',
        router_logout: '退出登录'
    },
    question: {
        tab_selector: '选择题',
        tab_subjective: '主观题',
        span_countquestion: '个题目在题库中。',
        btn_importquestion: '导入题目',
        btn_addquestion: '新增题目',
        label_target: '指标',
        label_target_en: '指标(英文)',
        label_number: '题目编号',
        label_content: '题目内容',
        label_content_en: '题目内容(英文)',
        label_isused: '是否被引用',
        label_options: '操作',
        notify_questionnull: '没有查找到任何题目',
        notify_questiontargetnull: '没有查找到任何指标，请先创建指标',
        title_add_question: '创建题目',
        title_revise_question: '修改题目',
        btn_save: '保存',
        notify_incompletely: '题目信息不完整',
        message_delete_question_confirm: '当前题目如果被问卷所使用，删除题目会导致问卷不可更改，请谨慎操作！'
    },
    questionnaire: {
        span_countquestionnaire: '个问卷。',
        btn_addquestionnaire: '新增问卷',
        notify_questionnairenull: '没有查找到任何问卷',
        label_questionnaire_title: '问卷名',
        label_questionnaire_title_en: '问卷名(英文)',
        title_edit_questionnaire: '编辑问卷',
        title_allquestions: '所有题目',
        title_selectedquestions: '已选题目',
        tag_questions_deleted_notice: '已删除的题目会显示为禁用状态，若已有问卷引用该题目，不可取消，若有其他方案请创建新问卷。',
        tag_sort_notice: '题目会按照您勾选顺序生成问卷，请注意排序。',
        btn_preview: '预览',
        notify_preview_later: '抱歉，暂时无法预览该问卷，请在答题端查看。',
        notify_some_saved_err: '部分题目保存出错，请检查。',
        notify_questionnaire_incompletely: '问卷信息不完整',
        notify_quesioninquesitonnairenull: '至少选择一个题目',
        title_add_questionnaire: '创建问卷',
        notify_delete_questionnaire: '删除该问卷可能会导致引用该问卷的活动无法变更，确认删除吗？',
        alert_noticerevise_body: '修改某个被活动引用的问卷可能导致答题出现结果不一致问题，请谨慎操作！'
    },
    target: {
        span_counttarget: '个指标。',
        btn_exporttp: '导出模板',
        btn_addtarget: '新增指标',
        label_target: '指标',
        label_target_en: '指标(英文)',
        label_options: '操作',
        title_add_target: '创建指标',
        btn_save: '保存',
        notify_incompletely: '指标信息不完整',
    },
    dept: {
        span_countdept: '个部门。',
        btn_adddept: '添加部门',
        label_dept: '部门名称',
        label_dept_en: '部门名称(英文)',
        label_options: '操作',
        notify_deptnull: '没有查找到任何部门！',
        title_add_dept: '创建部门',
        btn_save: '保存',
        notify_incompletely: '部门信息不完整',
    },
    staff: {
        span_countstaff: '个员工。',
        btn_addstaff: '新增员工',
        btn_synfromdt: '同步钉钉通讯录',
        btn_importonjob: '导入到系统',
        label_username: '账户名',
        label_password: '密码',
        label_dept: '部门',
        label_name: '用户全称',
        label_englishname: '英文名',
        label_sex: '性别',
        label_dept_name: '所属部门',
        label_ismanager: '拥有下级',
        label_options: '操作',
        label_dt_userid: '钉钉用户id',
        label_dt_name: '真实姓名',
        label_matchuser: '匹配的钉钉用户',
        notify_staffnull: '没有查找任何员工',
        title_add_staff: '添加员工',
        title_syn_dtuser: '钉钉在职员工',
        title_modify_staff: '修改员工信息',
        btn_save: '保存',
        notify_incompletely: '员工信息不完整',
        notify_username_password_length: '账户名和密码的长度应在3-15个字符内',
        alert_staff_use_body: "在此能够管理员工是否参与问卷答题，按'Ctrl' + 'F' 来搜索员工。",
        alert_staff_tip_body: '为避免用户重复，自动匹配创建功能暂未开启，请手动创建账号以新增员工，然后手动匹配。',
        notify_userexist_err: '此用户名已存在或被注册过',
        text_delete_staff_body: '真的要停用该员工吗？',
        text_synfromdt_body: '将从当前组织同步在职员工，在此之前请确保智能人事信息维护完成，获取数据可能会花费一些时间，请耐心等待。',
        text_import_onjob_body: '将把选中的在职员工导入到系统中，若选中员工中存在真实姓名未完善的，您可稍后进行手动匹配，建议同步完善智能人事数据。',
        notify_import_onjob_success: '导入成功！已自动过滤已存在的用户。',
        notify_import_warn: '部分用户导入失败。',
        notify_import_nochange: '选择的用户全部已存在，无需导入。',
        notify_no_onjobuser: "未找到本地钉钉通讯录记录，请先同步云通讯录"
    },
    activity: {
        notify_actnull: '没有查找到任何活动',
        span_countactivity: '个活动。',
        alert_notice_errorquestionnaire_body: '如果被评估人设置的问卷不匹配，会导致结果数据错误，请认真检查！',
        btn_addactivity: '创建活动',
        label_activity_title: '活动名称',
        label_options: '操作',
        label_process: '完成进度',
        btn_primary: '基础信息',
        btn_questionnaire: '问卷',
        btn_participant: '被评估人',
        btn_relations: '评估关系',
        title_add_activity: '创建活动',
        label_activity: '活动名称',
        label_activity_en: '活动名称(英文)',
        btn_save: '保存',
        notify_incompletely: '活动基本信息不完整',
        title_revise_activity: '修改活动',
        title_set_questionnaire: '设置问卷',
        alert_disabled_set_questionnaire_title: '问卷只能修改一次',
        alert_disabled_set_questionnaire_body: '如果已设置问卷，那么当前设置只允许查看',
        alert_disabled_seteparticipat_title: '被评估人只能修改一次',
        alert_disabled_seteparticipat_body: '如果已设置被评估人，那么当前设置只允许查看',
        notify_questionnaire_err_apart: '请求没有全部完成，请检查问卷设置',
        text_delete_message_body: '每个活动的问卷信息只能设置一次，确认现在提交吗？',
        text_participant_message_body: '每个活动的被评估人信息只能设置一次，确认现在提交吗？',
        text_start_activity_body: '请检查活动的各项设置是否设置完毕，开启活动后将不可更改，现在开启该活动吗？',
        title_allquestionnaires: '所有问卷',
        title_selectedquestionnaires: '已选问卷',
        title_set_participant: '选择被评估人',
        title_allparticipant: '可选员工',
        title_selectedparticipant: '被评估人',
        notify_questionnaireinactnull: '至少选择一个问卷',
        notify_participant_err_apart: '请求没有全部完成，请检查参与者设置',
        notify_participant_null_relations: '请先设置被评估人再设置评估关系',
        notify_questionnaire_null_relations: '请先设置问卷再设置评估关系',
        title_set_relations: '设置评估关系',
        label_participant: '被评估人',
        label_questionnaire_relations: '适用问卷',
        label_superior: '上级',
        label_equal: '同事',
        label_subordinate: '下级',
        label_self: '是否自评',
        label_result: '结果',
        title_revise_relation: '修改评估关系',
        notify_questionnaire_self_null: '必须选择一个问卷和自评，请勿非法操作',
        notify_relations_least: '至少要选择一个上下级和同事关系',
        notify_relations_err_apart: '请求没有全部完成，请检查评估关系设置',
        notify_super_null_err: '有未选择上级的项目',
        notify_equal_null_err: '有未选择同事的项目',
        notify_subordinate_null_err: '有未选择下级的项目',
        notify_relations_not_finish: '请先确认评估关系',
        title_incomplete_users: '未完成人员名单'
    },
    result: {
        main_title: '360度评议报告',
        staffname: '姓       名：',
        dept_name: '部       门：',
        total_score: '总       分：',
        report_time: '报告时间：',
        preface_span1: '  本报告是关于您的360度评估反馈报告，汇总了您自己，您的上级、同事、下级对您的评价反馈。',
        preface_h4_1: '此报告将帮助您：',
        preface_h4_2: '理解此份报告：',
        preface_ul_1_li_1: '1. 了解周围的人对您的胜任力水平的评价；',
        preface_ul_1_li_2: '2. 对自己的能力的认知与其他人的认知进行比较；',
        preface_ul_1_li_3: '3. 确定优势领域，在后续工作中更积极的展现；',
        preface_ul_1_li_4: '4. 确定待发展领域，在后续的工作中制定并实施发展计划。',
        preface_span2: '  请阅读此报告，了解您的优势与待发展领域。没必要纠结每一个细节信息，应关注评分结果的整体趋势，通过分析趋势来发现优势与待发展领域。',
        preface_span3: '  坦率直接的反馈接受起来或许有难度。请参考以下建议，保持积极的态度面对反馈。',
        preface_ul_2_li_1: '1. 保持开放心态，不抵触；',
        preface_ul_2_li_2: '2. 每个人都有优势与待发展领域；',
        preface_ul_2_li_3: '3. 反馈所评价的是工作行为，而非针对个人；',
        preface_ul_2_li_4: '4. 听取反馈，尽量理解其他人的观点；',
        preface_ul_2_li_5: '5. 认真考量反馈信息，不要将它们拒之门外；',
        preface_ul_2_li_6: '6. 表达您对反馈的想法和感受；',
        preface_ul_2_li_7: '7. 确保理解这些反馈并知道如何改善。',
        lebel_score5: '非常相符',
        lebel_score4: '比较相符',
        lebel_score3: '一般',
        lebel_score2: '不太相符',
        lebel_score1: '非常不相符',
        explain_1: '1. 360度评估采用5级计分，评议选项与分值的关系如上表所示；',
        explain_2: '2. 上级、同事、下级、自己的得分分别为以上各自评分的平均分；',
        explain_3: '3. 个人得分为各指标的加权平均分；',
        explain_4: '4. 公司平均分表示在该指标上公司所有参评人的个人得分的平均分；',
        explain_5: '5. 自评分数仅作为对照不参与加权计算；',
        explain_6: '6. 评议数据源和权重跟随您的职位级别自动变化。',
        tilte_preface: '前言',
        title_standard: '标准与统计',
        label_weight: '权重',
        title_target_s: '指标总结',
        title_info_source: '评议信息源',
        label_target_name: '指标',
        label_superior: '上级',
        label_equal: '同事',
        label_subordinate: '下级',
        label_self: '自己',
        label_all: '个人得分',
        label_total: '合计',
        label_score: '个人得分',
        label_company_avg: '公司平均分',
        chart_label_self: '自评',
        chart_label_weight: '个人得分',
        chart_label_score: '个人得分',
        chart_label_company_avg: '公司平均分',
        notify_questionnaire_isnot_finished: '参评人未完成对该用户的评估，暂无数据',
        target_type: '类型',
        table_text_position: '位置',
        title_target: '指标：',
        target_solve_discription: '以下部分汇总了调研中的每一项胜任力维度得分。其中包括不同的评价者群组（上级、同事、下级）以及综合以上群组（不包含您的自评）的整体平均分。个人得分那一列反映了了您的综合得分。公司平均分那一列反映了这一批被评分的所有参与者获得的评分结果。下图是位置栏目所表达的意义：',
        target_solve_discription_2: '直线表示整体个人得分范围，圆圈表示您在整体水平的位置',
        title_cognition: '认知差异',
        cognition_discription: '下述4个模块分别反映自己与他人对优势和待发展项目的共识点，需重点关注的是潜能区和盲区，它们反映了“他人眼中的您”和“自我认知”之间的不同。其中：潜能区是容易被您忽略的优势，他可以帮助您改善自我认知。通过对盲区的分析，有助于您找到不足并弥补盲点、发挥潜能。',
        cognition_li_1: '1. 优势共识区：自评高于自评均分，且个人得分高于公司平均分的指标；',
        cognition_li_2: '2. 潜能区：自评低于自评均分，且个人得分高于公司平均分的指标；',
        cognition_li_3: '3. 待发展共识区：自评低于自评均分，且个人得分低于公司平均分的指标；',
        cognition_li_4: '4. 盲区：自评高于自评均分，且个人得分低于公司平均分的指标。',
        cognition_card_title_1: '优势共识区',
        cognition_card_title_2: '潜能区',
        cognition_card_title_3: '待发展共识区',
        cognition_card_title_4: '盲区',
        title_behaviours: '优势行为与待发展行为',
        advantage_discription_1: '优势行为：所有题目中个人得分最高的5项内容。',
        advantage_discription_2: '优势行为：所有题目中个人得分最高的7项内容。',
        improvable_discription_1: '待发展行为：所有题目中个人得分最低的5项内容。',
        improvable_discription_2: '待发展行为：所有题目中个人得分最低的7项内容。',
        label_behaviour_item: '行为条目(题目/指标)',
        label_result_compare: '评议结果',
        label_difference: '差值',
        title_self_rating: '自我认知偏差',
        self_rating_discription: '自我认知偏差：所有题目中个人得分与自评分差分数最高的10项内容。',
        title_subjective_feedback: '开放性反馈',
        subjective_discription: '评价者在一些问题上给您的评价和建议，辅助您进一步了解他人的看法，并收获一些如何发展的启发。',
        label_question: '题目',
        title_score: '各维度题目得分'
    },
    activitylist: {
        welcome_prefix: '欢迎您，',
        conplete_all_questionnaire_alert: '你已完成所有问卷',
        activity_alert1: '当前有 ',
        activity_alert2: ' 套问卷是需要您自评或作为评估人完成的',
        label_activity: '项目',
        label_be_evaluate: '被评估人',
        label_type: '评估类型',
        label_options: '去填写',
        label_status: '状态',
        type_superior: '评价上级',
        type_subordinate: '评价下级',
        type_equal: '评价同事',
        type_self: '自评',
        notify_no_activity: '没有任何需要参与的答题活动',
        empty_activity_discription: '没有任何需要参与的答题活动',
        router_result: '结果',
        title_result: '你的所有评估结果',
        alert_resulttable: '仅展示已完成的活动结果',
        label_option: '查看',
        notify_result_not_allow: '管理员设置当前不可查看历史结果'
    },
    askform: {
        current_object: '当前被评估人：',
        notify_questionoptions_null: '资源服务器错误，请联系管理员(Options)',
        btn_submit_askform: '提交问卷',
        text_submit_questionnaire_body: '问卷提交后将不可更改，确认提交吗？',
        notify_incomplete_answer: '请作答完所有题目后再提交',
        notify_warn_apart: '问卷信息部分出错，请联系管理员',
        text_unfinished_qs: '未完成的题目：'
    }
}