module.exports = {
    title: {
        login: 'Login - iFi',
        logout: 'Logout - iFi',
        hrnavigation: 'Navigation - iFi',
        chips: 'Chips',
        iqtest: 'Standford-Binet IQ Test',
        bigfive: 'The bigfive survey',
        bigfive_results: 'Big Five - Results',
        lanesheet: 'Approval Report',
        approvalform: 'Approval Forms',
        iq_results: 'IQ - Results',
        keys: 'Keys',
        notfound: '404 Not found',
        console: 'Dashboard - 360',
        result: 'Result - iFi',
        activitylist: 'My activities - iFi'
    },
    notify: {
        title_server: 'Server',
        title_retry: 'Retry',
        title_notification: 'Notification',
        title_err_type1: 'Error code: -1',
        title_err_type2: 'Error code: 500',
        title_err_type_ill: 'Illegal operation',
        content_refused: 'Your request was refused.',
        content_server: 'Server timed out cause: ',
        content_loginerr: 'Incorrect username or password',
        content_ill: 'Action illegally',
        result_null: 'No more data.',
        dt_token_outofdate: 'Dingtalk information is out of date, try once again.',
        content_key_err: 'Input a correct key.',
        content_success: 'Success.',
        content_reported: 'Reported',
        cotent_all_items_required: 'All items are required.'
    },
    text: {
        placeholder: 'Input...',
        placeholder_select: 'Select one',
        placeholder_pick_day: 'Pick a day',
        message_saved: 'Saved',
        message_deleted: 'Deleted',
        message_nochange: 'No change',
        message_delete_confirm: 'Would you want wo delete it?',
        sex_man: 'Man',
        sex_woman: 'Woman',
        text_delete_message_title: 'Delete?',
        text_canceled: 'Canceled',
        text_yes: 'Yes',
        text_no: 'No',
        text_notset: 'Not set',
        text_confirm_title: 'Confirm',
        btn_refresh: 'Refresh',
        text_complete: 'Compeleted',
        text_incomplete: 'Unfinished',
        text_to_be_started: 'To be started',
        btn_print: 'Print',
        one: '1',
        two: '2',
        three: '3',
        four: '4',
        five: '5',
        six: '6',
        seven: '7',
        eight: '8',
        nine: '9',
        ten: '10',
        eleven: '11',
        identical: 'Identical',
        not_available: 'No available',
        inused: 'In used',
        notused: 'Be not used',
        text_powered_by: '⚡ Powered by iFi Team.',
        second: 'Second(s)',
        minute: 'Minute(s)',
        record: 'records(s).',
        unused: 'Available',
        inusing: 'In using',
        used: 'Expired',
        keyword: 'Key',
        status: 'Status',
        key_title: 'Key?',
        option: 'Option',
        reset_confirm_body: 'This key will be reset, continue?',
        expire_confirm_body: 'This key will be expired, continue?',
    },
    common: {
        notify_import: 'Disabled',
        notity_title_message: 'Message',
        notify_title_error: 'Error',
        notify_empty_assets: '500 error in server, connect your administrator',
        btn_revise: 'Edit',
        btn_delete: 'Delete',
        btn_import: 'Import',
        btn_save: 'Save',
        btn_message_confirm: 'Confirm',
        btn_message_cancel: 'Cancel'
    },
    hrnavigation: {
        top_title: 'Internal use only',
        btn_toiqresults: 'View IQ records',
        btn_tobigfiveresults: 'View Big five records',
        btn_to_360: 'To login 360'
    },
    login: {
        placehoder_username: 'Username...',
        placehoder_password: 'Password...',
        btn_login: 'Login',
        notify_username_null: 'Username is required',
        notify_password_null: 'Password is required',
        notify_usernamepassword_lengtherror: 'Username or password is illegal',
        loadingviadingtalk: 'Waiting for dingtalk...',
        notify_dingtalk_not_bound: 'You do not bind Dingtalk, open management page or contact your administrator please.',
        auth_err: 'Authorized failed, tay again later.'
    },
    logout: {
        title_logout: 'Logout success',
        btn_tologin: 'To login'
    },
    notfound: {
        title_notfound: 'This page is not found - 404',
        subtitle_notfound: 'Failed to reach the page.'
    },
    chips: {
        mpn: 'mpn',
        k3id: 'Material id',
        country: 'Country',
        distributor: 'Distributor',
        moq: 'MOQ',
        sku: 'SKU',
        stock: 'Stock',
        updated: 'Updated',
        count_stock: 'Total stock',
        span_updated: 'Last updated：',
        notify_chiptemplate_null: 'Template is null.',
        notify_chipdata_partly: 'Some data not update.',
        tip_apichip_update: 'Click to update',
        text_updatefromapi_body: 'We will update data from 3-rd party, parts Limit will be consumed, continue?'
    },
    iqtest: {
        tip_top1: 'This test is customized for you who is over 11 years old.',
        tip_top2: 'To answer the following 60 questions in 45 minutes and you can calculate with pen and paper.',
        title_iqs: 'IQ Askform',
        content_title_ask_age: 'How old are you?',
        age_1: 'age 11 and younger',
        age_2: '11 Years old',
        age_3: '12 Years old',
        age_4: '13 Years old',
        age_5: '14 Years old',
        age_6: '15 Years old',
        age_7: 'age 16 and older',
        notify_age_unselected: 'Age is unselected!',
        btn_submit: 'Submit now!',
        notify_unfinished_number1: 'No. ',
        notify_unfinished_number2: ' is unselected.',
        text_rs_score: 'Your score'
    },
    iqresults: {
        top_title: 'IQ - Results',
        score: 'Score',
        duration: 'T.C.',
        label_match_post: 'Matching position',
        label_standard_score_range: 'Standard range',
        label_anal_result: 'Result',
        text_higher: 'Higher than standard',
        text_normal: 'Normal',
        text_slightly_lower: 'Slightly lower than standard',
        text_lower: 'Lower than standard'

    },
    bigfive: {
        title_ask_info: 'THE BIG FIVE 大五类人格测试',
        content_ask_info: 'Be careful to fill in information:',
        label_fullname: 'Your Name',
        label_post: 'Job Title',
        notify_ask_unfinished: 'Name and post are required.',
        toptext_1: '',
        toptext_2: 'questions.',
        option_1: '非常不符合',
        option_2: '不太符合',
        option_3: '不太确定',
        option_4: '比较符合',
        option_5: '非常符合',
        btn_redo: 'Redo',
        askform_title: 'THE BIG FIVE 大五类人格测试',
        top_tip_text: '在10分钟内不假思索完成。',
        top_tip_text2: '真实呈现自己，而非期待的样子。',
        top_tip_text3: '尽量避免“中立”的答案。',
        top_tip_text: '请仔细阅读下列各题，并在右边适当的数字上画圈，以表示您对每一题目赞同或不赞同的程度。',
        targetN: '神经质',
        targetE: '外倾性',
        targetO: '开放性',
        targetA: '友善性',
        targetC: '严谨性',
        explain_title_1: 'Neuroticism',
        explain_title_2: 'Extraversion',
        explain_title_3: 'Openness',
        explain_title_4: 'Agreeableness',
        explain_title_5: 'Conscientiousness',
        explain_content_1_1: '神经质指个体体验消极情绪的倾向。',
        explain_content_1_2: '神经质维度得分高的人更容易体验到诸如愤怒、焦虑、抑郁等消极的情绪。他们对外界刺激反应比一般人强烈，对情绪的调节能力比较差，经常处于一种不良的情绪状态下。并且这些人思维、决策、以及有效应对外部压力的能力比较差。',
        explain_content_1_3: '神经质维度得分低的人较少烦恼，较少情绪化，比较平静，但这并不表明他们经常会有积极的情绪体验，积极情绪体验的频繁程度是外向性的主要内容。',
        explain_content_2_1: '外向代表了在外界投入的能量。',
        explain_content_2_2: '高外向的人喜欢与人接触，充满活力，经常感受到积极的情绪。他们热情，喜欢运动，喜欢刺激冒险。在一个群体当中，他们非常健谈，自信，喜欢引起别人的注意。',
        explain_content_2_3: '低外向的人比较安静，谨慎，不喜欢与外界过多接触。他们不喜欢与人接触不能被解释为害羞或者抑郁，这仅仅是因为比起高外向的人，他们不需要那么多的刺激，因此喜欢一个人独处。低外向人的这种特点有时会被人误认为是傲慢或者不友好，其实一旦和他接触你经常会发现他是一个非常和善的人。',
        explain_content_3_1: '开放性描述一个人的认知风格。',
        explain_content_3_2: '开放性得分高的人富有想象力和创造力，好奇，欣赏艺术，对美的事物比较敏感。开放性的人偏爱抽象思维，兴趣广泛。开放性的人适合教授等职业。',
        explain_content_3_3: '开放性得分低(即封闭性)的人讲求实际，偏爱常规，比较传统和保守。封闭性的人适合警察、销售、服务性职业等。',
        explain_content_4_1: '代表了“爱”，对合作和人际和谐是否看重。',
        explain_content_4_2: '友善性高的人是善解人意的、友好的、慷慨大方的、乐于助人的，愿意为了别人放弃自己的利益。友善性高的人对人性持乐观的态度，相信人性本善。',
        explain_content_4_3: '友善性低的人则把自己的利益放在别人的利益之上。本质上，他们不关心别人的利益，因此也不乐意去帮助别人。有时候，他们对别人是非常多疑的，怀疑别人的动机。对于某些职位来说，太高的友善性是没有必要的，尤其是需要强硬和客观判断的场合，例如科学家、评论家和士兵。',
        explain_content_5_1: '指我们控制、管理和调节自身冲动的方式。',
        explain_content_5_2: '高严谨性的人容易避免麻烦，能够获得更大的成功。人们一般认为高严谨的人更加聪明和可靠，但是高严谨的人可能是一个完美主义者或者是一个工作狂。极端严谨的个体让人觉得单调、乏味、缺少生气。',
        explain_content_5_3: '低严谨性的人常被认为是快乐的、有趣的、很好的玩伴。但是冲动的行为常常会给自己带来麻烦，虽然会给个体带来暂时的满足，但却容易产生长期的不良后果，比如攻击他人，吸食毒品等等。低严谨性的个体一般不会获得很大的成就。冲动并不一定就是坏事，有时候环境要求我们能够快速决策。'
    },
    bigfiveresults: {
        top_title: 'Big Five Results',
        survey_name: 'Name',
        survey_post: 'Position',
        survey_time: 'Time',
        empty_results_discription: 'No results yet.',
        alert_notice_results: 'Click name to view result.'
    },
    lanesheet: {
        transfer_application: 'Approval of Probationary Application',
        job_transfer_application: 'Job Transfer Application',
        resignation_handover_report: 'Resignation Handover Report',
        employee: 'Employee Name',
        title: 'Title',
        department: 'Department',
        dateOfEntry: 'Entry Date',
        transferDate: 'Transfer Date',
        btn_report: 'Submit',
        newdept: 'New Department',
        newtitle: 'New Title',
        dateOfTransferred: 'Transferred Date',
        approver1: 'Original Department Approver',
        approver2: 'Transferred Department Approver',
        reason: 'Reason for Transfer',
        superior: 'Superior Name',
        created_time: 'Created time',
        checked: 'Checked'
    },
    console: {
        menu_title: 'Dashboard',
        menu_question: ' Question',
        menu_questionnaire: ' Questionnaire',
        menu_activity: ' Activity',
        menu_properties: ' Resource',
        menu_staff: ' Employee',
        submenu_target: 'Indicator',
        submenu_dept: 'Department',
        submenu_weight: 'Weight',
        router_logout: 'Logout'
    },
    question: {
        tab_selector: 'Multiple-choice questions',
        tab_subjective: 'Subjective questions',
        span_countquestion: ' question(s) in question base.',
        btn_importquestion: 'Import',
        btn_addquestion: 'Create new question',
        label_target: 'Indicator',
        label_target_en: 'Indicator(English)',
        label_number: 'Number',
        label_content: 'Content',
        label_content_en: 'Content(English)',
        label_isused: 'Status',
        label_options: 'Option',
        notify_questionnull: 'No question has been found.',
        notify_questiontargetnull: 'No indicator has been found, create it first.',
        title_add_question: 'Create question',
        title_revise_question: 'Revise question',
        btn_save: 'Save',
        notify_incompletely: 'Question information incompletely',
        message_delete_question_confirm: 'Some Questionnaires that used it will be disabled if you delete it. Are you sure?'
    },
    questionnaire: {
        span_countquestionnaire: ' questionnaire(s).',
        btn_addquestionnaire: 'Create new questionnaire',
        notify_questionnairenull: 'No questionnaire has been found',
        label_questionnaire_title: 'Name',
        label_questionnaire_title_en: 'Name(English)',
        title_edit_questionnaire: 'Edit questionnaire',
        title_allquestions: 'All questions',
        title_selectedquestions: 'Seleted questions',
        tag_questions_deleted_notice: 'Invalid question in box is disabled, It can not be removed if it was used for questionnaire. Create a new questionnaire to solve it.',
        tag_sort_notice: 'Questions will sort by your selecting sequence.',
        btn_preview: 'Preview',
        notify_preview_later: 'Failed to open it. look over it on examination page.',
        notify_some_saved_err: 'Failed to save some questions, check it manually.',
        notify_questionnaire_incompletely: 'Questionnaire information incompletely',
        notify_quesioninquesitonnairenull: 'Select one question at least',
        title_add_questionnaire: 'Create questionnaire',
        notify_delete_questionnaire: 'Some Activties that used it will be disabled if you delete it. Are you sure?',
        alert_noticerevise_body: 'It makes a difference if you revise a questionnaire that belongs to an activity, do it carefully!'
    },
    target: {
        span_counttarget: ' indicator(s).',
        btn_exporttp: 'Export Template',
        btn_addtarget: 'Create new indicator',
        label_target: 'Indicator',
        label_target_en: 'Indicator(English)',
        label_options: 'Option',
        title_add_target: 'Create indicator',
        btn_save: 'Save',
        notify_incompletely: 'Indicator information incompletely',
    },
    dept: {
        span_countdept: ' department(s).',
        btn_adddept: 'Create new dept',
        label_dept: 'Name',
        label_dept_en: 'Name(English)',
        label_options: 'Option',
        notify_deptnull: 'No dept has been found.',
        title_add_dept: 'Create a dept',
        btn_save: 'Save',
        notify_incompletely: 'Indicator information incompletely',
    },
    staff: {
        span_countstaff: ' employee(s).',
        btn_addstaff: 'Create new employee',
        btn_synfromdt: 'Synchronize from Dingtalk',
        btn_importonjob: 'Save to system',
        label_username: 'Username',
        label_password: 'Password',
        label_dept: 'Department',
        label_name: 'Full name',
        label_englishname: 'English name',
        label_sex: 'Gender',
        label_dept_name: 'Department',
        label_ismanager: 'Has subordinate',
        label_options: 'Option',
        label_dt_userid: 'Dingtalk userid',
        label_dt_name: 'Real name',
        label_matchuser: 'Matching dingtalk user',
        notify_staffnull: 'No employee has been found',
        title_add_staff: 'Add employee',
        title_syn_dtuser: 'On job employee',
        title_modify_staff: 'Modify employee',
        btn_save: 'Save',
        notify_incompletely: 'Staff information incompletely',
        notify_username_password_length: 'Username and password must be between 3,15 characters',
        alert_staff_use_body: "Set employee who can tack part in activities you have been hold. Press 'Ctrl' + 'F' to search.",
        alert_staff_tip_body: '为避免用户重复，自动匹配创建功能暂未开启，请手动创建账号以新增员工，然后手动匹配。',
        notify_userexist_err: 'Username exist or registed',
        text_delete_staff_body: 'Do you want to remove it?',
        text_synfromdt_body: 'It will be downloading address book form Dingtalk, it will be took some time...',
        text_import_onjob_body: 'It will be importing employee to system, you can match them later if someone are not set real name.',
        notify_import_onjob_success: 'Import success and skip existing.',
        notify_import_warn: 'Some users import failed.',
        notify_import_nochange: 'All of users you selected are existing already.',
        notify_no_onjobuser: "No records found, synchronize from Dingtalk first."
    },
    activity: {
        notify_actnull: 'No activity has been found.',
        span_countactivity: ' activity/activities.',
        alert_notice_errorquestionnaire_body: 'Results of evaluation will incorrect if questionnaire is not match.',
        btn_addactivity: 'Create new activity',
        label_activity_title: 'Activity name',
        label_options: 'Option',
        label_process: 'Process',
        btn_primary: 'Primary',
        btn_questionnaire: 'Questionnaire',
        btn_participant: 'People',
        btn_relations: 'Relation',
        title_add_activity: 'Create activity',
        label_activity: 'Name',
        label_activity_en: 'Name(English)',
        btn_save: 'Save',
        notify_incompletely: 'Activity information incompletely.',
        title_revise_activity: 'Revise activity',
        title_set_questionnaire: 'Set questionnaire',
        alert_disabled_set_questionnaire_title: 'This information change opportunity is limited to once.',
        alert_disabled_set_questionnaire_body: 'This information is read only if you were already selected questionnaire.',
        alert_disabled_seteparticipat_title: 'This information change opportunity is limited to once.',
        alert_disabled_seteparticipat_body: 'This information is read only if you were already selected object of evaluation.',
        notify_questionnaire_err_apart: 'Request is not complete, check your questionnaires of activity',
        text_delete_message_body: 'This information change opportunity is limited to once, submit it now?',
        text_participant_message_body: 'Object of evaluation change opportunity is limited to once, submit it now?',
        text_start_activity_body: 'All setting of activity will be disabled if you start it, do it now?',
        title_allquestionnaires: 'All questionnaire',
        title_selectedquestionnaires: 'Selected questionnaire',
        title_set_participant: 'Select participants',
        title_allparticipant: 'All Staff',
        title_selectedparticipant: 'Participant',
        notify_questionnaireinactnull: 'Select one questionnaire at least',
        notify_participant_err_apart: 'Request is not complete, check your participants of activity',
        notify_participant_null_relations: 'You should set paricipants first before set relations.',
        notify_questionnaire_null_relations: 'You should set questionnaire first before set relations.',
        title_set_relations: 'Set relation',
        label_participant: 'People',
        label_questionnaire_relations: 'Questionnaire',
        label_superior: 'Superior',
        label_equal: 'Equal',
        label_subordinate: 'Subordinate',
        label_self: 'Need self-assessment',
        label_result: 'Result',
        title_revise_relation: 'Revise relation',
        notify_questionnaire_self_null: 'You must set questionnaire and self-appraisal, do not submit illegally!',
        notify_relations_least: 'Set one of superior, colleague, subordinate at least.',
        notify_relations_err_apart: 'Request is not complete, check your relations of activity',
        notify_super_null_err: 'An item of superior is unselected.',
        notify_equal_null_err: 'An item of colleague is unselected.',
        notify_subordinate_null_err: 'An item of subordinate is unselected.',
        notify_relations_not_finish: 'Set and confirm relations of evaluation first',
        title_incomplete_users: 'Unfinished people'
    },
    result: {
        main_title: 'Evaluation Report',
        staffname: 'Name:',
        dept_name: 'Department:',
        total_score: 'Score:',
        report_time: 'Report time:',
        preface_span1: 'It is the report of your 360 evaluation, includes what others think of you.',
        preface_h4_1: 'The report will help you:',
        preface_h4_2: 'To understand this report, and:',
        preface_ul_1_li_1: '1.To understand What others say about your ability to do your job.',
        preface_ul_1_li_2: '2.To know your capabilities and compare with others.',
        preface_ul_1_li_3: '3.To find your advantages and bring it into your job.',
        preface_ul_1_li_4: '4.To find your disadvantages and improve it.',
        preface_span2: 'Attending the trends of results and try to understand it.',
        preface_span3: 'Reading this report with a positive attitude.',
        preface_ul_2_li_1: '1.Keep your mind wide open.',
        preface_ul_2_li_2: '2.Everyone has strengths and weaknesses.',
        preface_ul_2_li_3: '3.It is business, not personal.',
        preface_ul_2_li_4: '4.Listen and understand to other ideas.',
        preface_ul_2_li_5: '5.Do not turn them away.',
        preface_ul_2_li_6: '6.Express your feelings and thoughts.',
        preface_ul_2_li_7: '7.Understand all of them and know how to improve.',
        lebel_score5: 'Extremely Accurate',
        lebel_score4: 'Very Accurate',
        lebel_score3: 'Somewhat Accurate',
        lebel_score2: 'Not very Accurate',
        lebel_score1: 'Not Accurate At all',
        explain_1: '1.Scoring rules are in the table above.',
        explain_2: '2.We will provide the average score with your superiors,colleagues,subordinates and yourself.',
        explain_3: "3.'Individual scores' with each indicator are the weighted average.",
        explain_4: "4.'Average score of company' is the weighted average all of all scores.",
        explain_5: '5.Score of Self-Assessment are comparison only.',
        explain_6: '6.Data sources and weights change automatically according to your job level.',
        tilte_preface: 'Preface',
        title_standard: 'Standard and Statistics',
        label_weight: 'Weight',
        title_target_s: 'Indicator analysis',
        title_info_source: 'Data source',
        label_target_name: 'Indicator',
        label_superior: 'Superior',
        label_equal: 'Colleague',
        label_subordinate: 'Subordinate',
        label_self: 'Yourself',
        label_all: 'Individual score',
        label_total: 'Total',
        label_score: 'Individual score',
        label_company_avg: 'Average score of company',
        chart_label_self: 'Self-Assessment',
        chart_label_weight: 'Individual score',
        chart_label_score: 'Individual score',
        chart_label_company_avg: 'Average score of company',
        notify_questionnaire_isnot_finished: 'This questionnaire is not finish.',
        target_type: 'Type',
        table_text_position: 'Location',
        title_target: 'Indicator:',
        target_solve_discription: "We collect your scores with each indicator in the following tables including superior's, colleague's and subordinate's(if any). And a simple picture will help you to understand them.",
        target_solve_discription_2: 'Discription3',
        title_cognition: 'Difference of cognition',
        cognition_discription: "Your indicators are distributed in the follwing 4 areas, focus on 'Potential Area' and 'Blind Area', other people's views differ from yours on these indicators.",
        cognition_li_1: 'cognition_li1',
        cognition_li_2: 'cognition_li2',
        cognition_li_3: 'cognition_li3',
        cognition_li_4: 'cognition_li4',
        cognition_card_title_1: 'Strengths Consensus Area',
        cognition_card_title_2: 'Potential Area',
        cognition_card_title_3: 'To be improved Consensus Area',
        cognition_card_title_4: 'Blind Area',
        title_behaviours: 'Your strengths and ablilities thar you can improve',
        advantage_discription_1: 'Your strengths: Top 5 individual scores(with their indicator).',
        advantage_discription_2: 'Your strengths: Top 7 individual scores(with their indicator).',
        improvable_discription_1: 'Ablilities that you can improve: Bottom 5 individual scores(with their indicator).',
        improvable_discription_2: 'Ablilities that you can improve: Bottom 7 individual scores(with their indicator).',
        label_behaviour_item: 'Item(Question/Indicator)',
        label_result_compare: 'Result/Comparison',
        label_difference: 'Difference',
        title_self_rating: 'Self-perception Biases',
        self_rating_discription: 'Self-perception Biases: Top 10 items with the highest individual score and Self-Assessment difference among all questions.',
        title_subjective_feedback: 'Open feedback',
        subjective_discription: 'Evaluators give you comments and advice on a number of issues to help you further understand the views of others and gain some inspiration on how to develop them.',
        label_question: 'Question',
        title_score: 'Score for each question'
    },
    activitylist: {
        welcome_prefix: 'Welcome, ',
        conplete_all_questionnaire_alert: 'All questionnaires have been finished.',
        activity_alert1: '',
        activity_alert2: ' questionnaire(s) below you have to fill in and they are anonymous.',
        label_activity: 'Project',
        label_be_evaluate: 'Name',
        label_type: 'Relationship',
        label_options: 'Go',
        label_status: 'Status',
        type_superior: 'Your superior',
        type_subordinate: 'Your subordinate',
        type_equal: 'Your colleague',
        type_self: 'Yourself',
        notify_no_activity: 'No activity at the moment.',
        empty_activity_discription: 'No activity at the moment.',
        router_result: 'Result',
        title_result: 'Your result',
        alert_resulttable: 'Show completed activities only.',
        label_option: 'View',
        notify_result_not_allow: 'Results are not allowed to view now.'
    },
    askform: {
        current_object: 'Name:',
        notify_questionoptions_null: '500 error in server, connect your administrator(Option)',
        btn_submit_askform: 'Submit',
        text_submit_questionnaire_body: 'Your answers will be saved, confirm or not?',
        notify_incomplete_answer: 'You have to finish all items!',
        notify_warn_apart: 'Got exceptions, contact you administrator',
        text_unfinished_qs: 'Unfinished items: '
    }
}